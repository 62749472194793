import "../styles/globals.css";
import type { AppProps } from "next/app";
import Head from "next/head";
import "leaflet/dist/leaflet.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";
import Script from "next/script";
import ReactGA from "react-ga4";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ProgressBar from "@/src/components/atoms/progressBar";

const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID || ""; // Replace with your actual Measurement ID

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const [queryClient] = useState(() => new QueryClient());

  useEffect(() => {
    if (!GA_MEASUREMENT_ID) {
      console.error("GA_TRACKING_ID not found in environment variables");
      return;
    }

    ReactGA.initialize(GA_MEASUREMENT_ID);

    const handleRouteChange = (url: string) => {
      ReactGA.send({ hitType: "pageview", page: url });
    };

    // Track the initial page load
    handleRouteChange(window.location.pathname);

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <title>
          Jaringan Dokumentasi dan Informasi Hukum DPRD Provinsi Bali
        </title>
        <meta
          name="title"
          content="Jaringan Dokumentasi dan Informasi Hukum DPRD Provinsi Bali"
        />
        <meta
          name="description"
          content="Website resmi Jaringan Jaringan Dokumentasi dan Informasi Hukum DPRD Provinsi Bali, membantu kamu mencari peraturan DPRD Provinsi Bali"
        />
        <meta name="keywords" content="JDIH DPRD Prov Bali" />
        <meta name="url" content="https://jdih-dprd.baliprov.dev" />
        <meta name="article:author" content="" />
        <meta
          property="og:title"
          content="Jaringan Dokumentasi dan Informasi Hukum DPRD Provinsi Bali"
        />
        <meta
          property="og:description"
          content="Website resmi Jaringan Jaringan Dokumentasi dan Informasi Hukum DPRD Provinsi Bali, membantu kamu mencari peraturan DPRD Provinsi Bali"
        />
        <meta property="og:type" content="website" />
        <meta
          name="og:title"
          content="Jaringan Dokumentasi dan Informasi Hukum DPRD Provinsi Bali"
        />
        <meta name="og:author" content="" />
      </Head>
      <ProgressBar />
      <QueryClientProvider client={queryClient}>
        <Component {...pageProps} />
      </QueryClientProvider>

      <ToastContainer />
    </>
  );
};

export default MyApp;
